import React, { useEffect, useState } from 'react';
import { fetchSignups } from '../redux/actions/signupMetricsActions';
import { fetchServices } from "../redux/actions/servicesActions";
import { fetchLocations } from "../redux/actions/locationsActions";
import { fetchSources } from "../redux/actions/sourcesActions";
import { connect } from 'react-redux';
import { DatePicker } from "react-tempusdominus-bootstrap";
import { DateTime } from 'luxon';
import _ from 'lodash';
import ReactEcharts from 'echarts-for-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  cleanServiceName,
  defaultStartDate,
  servicesRevenueTimeSeriesChartOption,
  serviceRevenuePieChartOption,
  sourceRevenuePieChartOption,
  averagePlanSizeByMonthBarChartOption,
  revenueByLocationBarChartOption,
  signupSourceFrequencyBarChartOption,
  averagePlanLengthBarChartOption
} from "../utils";

function SignUpsPage({
      fetchSignupsAction,
      fetchServicesAction,
      serviceTotals,
      sourceTotals,
      averagePurchasesAmount,
      averagePlanLength,
      dateTimeDomain,
      locationsTotals,
      fetchLocationsAction,
      fetchSourcesAction,
      locations,
      services,
      sources,
      registrationsInitiated,
      registrationsPaid,
      registrationsNotPaid
     }) {
  const [start, setStart] = useState(defaultStartDate());
  const [end, setEnd] = useState(DateTime.local().endOf('day'));
  const [granularity, setGranularity] = useState('month');
  const [continent, setContinent] = useState('All');
  const [country, setCountry] = useState('All');
  const [provState, setProvState] = useState('All');
  const [city, setCity] = useState('All');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);

  useEffect(() => {
    if (_.isEmpty(locations.all)) {
      fetchLocationsAction();
      fetchSourcesAction();
    }
    if (_.isEmpty(services) || _.isEmpty(sources)) {
      fetchServicesAction().then(response => {
        const scopedServices = response.data;
        setSelectedProducts(scopedServices);
        return fetchSourcesAction().then(response => {
          const scopedSources = response.data;
          setSelectedSources(scopedSources)
          return fetchRevenueData({ selectedProducts: scopedServices, selectedSources: scopedSources })
        });
      });
    } else {
      setSelectedProducts(services);
      fetchRevenueData({ selectedProducts: services, selectedSources: selectedSources })
    }
  }, []);

  function fetchRevenueData(opts = {}) {
    if (end && end > start) {
      fetchSignupsAction({
        start: opts.start || start.toISODate(),
        end: opts.end || end.toISODate(),
        continent: opts.continent || continent,
        country: opts.country || country,
        provenceState: opts.provState || provState,
        city: opts.city || city,
        services: opts.selectedProducts || selectedProducts,
        sources: opts.selectedSources || selectedSources
      })
    }
  }

  function granularityDisplay() {
    return granularity.split('').map((char, i) => i === 0 ? char.toUpperCase() : char).join('')
  }

  function renderSignupRevenueLineChart() {
    let option = {}
    if (!_.isEmpty(serviceTotals)) {
      option = servicesRevenueTimeSeriesChartOption(serviceTotals.service_totals_by_datetime, dateTimeDomain)
    }
    const title = `New Signup Revenue by ${granularityDisplay()}`

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>{title} 
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-timeseries-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-timeseries-top">
                    Signup revenue monthly timeseries by service
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderSignupRevenuePieChart() {
    let option = {}
    if (!_.isEmpty(serviceTotals)) {
      option = serviceRevenuePieChartOption(serviceTotals.totals_by_service);
    }
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>New Signup Revenue by Service
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-totals-by-service-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-totals-by-service-top">
                    Signup revenue totals by service
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderSourceRevenuePieChart() {
    let option = {}
    if (!_.isEmpty(sourceTotals)) {
      option = sourceRevenuePieChartOption(sourceTotals);
    }
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>New Signup Revenue by Source
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-totals-by-service-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-totals-by-service-top">
                    Signup revenue totals by source
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderAveragePlanSizeBarChart() {
    let option = {}
    let title = ''
    if (!_.isEmpty(averagePurchasesAmount)) {
      option = averagePlanSizeByMonthBarChartOption(averagePurchasesAmount.average_by_datetime)
      title = `Signup Revenue Average Plan Size by ${granularityDisplay()}`
    }

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
        <h4 className='card-title'>{title}
          <span className='float-right'>
            <OverlayTrigger
              key='signup-revenue-monthly-plan-size-top'
              placement='top'
              overlay={
                <Tooltip id="signup-revenue-monthly-plan-size-top">
                  Average signup revenue by month (average plan size among new customers each month)
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
        </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function totalSignupsAveragePlanSize(){
    let totalAvgPlanSize = 0
    if (!_.isEmpty(averagePurchasesAmount)) {
      const avg = parseFloat(averagePurchasesAmount.overall_average.toFixed(2))
      totalAvgPlanSize = avg ? (<h3 className='display-2'>{`$${avg}`}</h3>) : (<h3 >Unknown</h3>);
    }

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>Signups Overall Average Plan Size
          <span className='float-right'>
            <OverlayTrigger
              key='signup-revenue-overall-average-size-top'
              placement='top'
              overlay={
                <Tooltip id="signup-revenue-overall-average-size-top">
                  Average Plan Revenue for All New Customers
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
          
          </h4>
          <div className="d-flex align-items-center justify-content-center" style={{height: '350px'}}>
            {totalAvgPlanSize}
          </div>
        </div>
      </div>
    );
  }

  function handleStartChange(evt) {
    setStart(DateTime.fromISO(evt.date.toISOString()))
  }

  function handleEndChange(evt) {
    setEnd(DateTime.fromISO(evt.date.toISOString()))
  }

  function signupsSourceFrequencyBarChart() {
    let option = {}
    if (!_.isEmpty(registrationsInitiated)) {
      option = signupSourceFrequencyBarChartOption(
          registrationsInitiated.filter((x, i) => i < 16),
          registrationsPaid.filter((x, i) => i < 16),
          registrationsNotPaid.filter((x, i) => i < 16)
      )
    }
    return (
      <div className='card chart-container-md' style={{height: 920}}>
        <div className='card-body'>
        <h4 className='card-title'>Signup Source Frequence
          <span className='float-right'>
            <OverlayTrigger
              key='signup-source-frequence'
              placement='top'
              overlay={
                <Tooltip id="signup-source-frequence">
                  Signups by source
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
        </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '880px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function totalsByLocationBarChart() {
    let option = {}
    if (!_.isEmpty(locationsTotals)) {
      option = revenueByLocationBarChartOption(locationsTotals)
    }
    return (
      <div className='card chart-container-md' style={{height: 550}}>
        <div className='card-body'>
        <h4 className='card-title'>Signup Revenue by Location
          <span className='float-right'>
            <OverlayTrigger
              key='signup-revenue-by-user-location-top'
              placement='top'
              overlay={
                <Tooltip id="signup-revenue-by-user-location-top">
                  Total new signup revenue by location of user
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
        </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '540px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function totalSignupRevenue() {
    let totalRevenue = 0
    if (!_.isEmpty(locationsTotals)) {
      totalRevenue = Math.round(locationsTotals.reduce((acc, cur) => acc + cur.value, 0))
    }
    return (
      <div className='card chart-container-md' style={{height: 550}}>
        <div className='card-body'>
          <h4 className='card-title'>Total Signup Revenue
          <span className='float-right'>
            <OverlayTrigger
              key='signup-revenue-total-top'
              placement='top'
              overlay={
                <Tooltip id="signup-revenue-total-top">
                  Total revenue due to new signups
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
          </h4>
          <div className="d-flex align-items-center justify-content-center" style={{height: '450px'}}>
            <h2 className='display-2'>${totalRevenue.toLocaleString()}</h2>
          </div>
        </div>
      </div>
    );
  }

  function avgPlanLengthByPlanBarChart() {
    let option = {}
    if (!_.isEmpty(averagePlanLength)) {
      const sortedSeries = _.sortBy(averagePlanLength.services, s => parseFloat(s.average_days.toFixed(2)))
      option = averagePlanLengthBarChartOption(sortedSeries)
    }
    
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
        <h4 className='card-title'>Signups Average Plan Length (Days) By Service
          <span className='float-right'>
            <OverlayTrigger
              key='signup-revenue-average-plan-length-days-by-service-top'
              placement='top'
              overlay={
                <Tooltip id="signup-revenue-average-plan-length-days-by-service-top">
                  Average plan length in days of new signup revenue plans by service
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
        </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function totalAvgPlanLength() {
    let totalAvgPlanLength = 0
    if (!_.isEmpty(averagePlanLength)) {
      totalAvgPlanLength = averagePlanLength.total_plan_length.toFixed(2)
    }

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>Signups Overall Average Plan Length (Days)
          <span className='float-right'>
            <OverlayTrigger
              key='signup-revenue-average-plan-length-days-top'
              placement='top'
              overlay={
                <Tooltip id="signup-revenue-average-plan-length-days-top">
                  Average plan length in days of new signup revenue plans
                </Tooltip>
              }>
              <i className='fa fa-info-circle' aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
          </h4>
          <div className="d-flex align-items-center justify-content-center" style={{height: '350px'}}>
            <h2 className='display-2'>{totalAvgPlanLength}</h2>
          </div>
        </div>
      </div>
    );
  }

  function onContinentChange(evt) {
    const val = evt.target.value
    setContinent(val);
    setCountry('All');
    setProvState('All');
    setCity('All');
  }

  function onCountryChange(evt) {
    const val = evt.target.value
    setCountry(val);
    setProvState('All');
    setCity('All');
  }

  function onProvStateChange(evt) {
    const val = evt.target.value
    setProvState(val);
    setCity('All');
  }

  function onCityChange(evt) {
    setCity(evt.target.value);
  }

  function renderContinents() {
    return !_.isEmpty(locations.continents) && (
      <div className='form-group row'>
        <div className='col-md-8'>
          <label>Continent</label>
          <div className='control'>
            <select className='form-control' value={continent} onChange={onContinentChange}>
              <option value="All">All</option>
              {locations.continents.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderCountries() {
    let countries
    if (continent !== 'All') {
      countries = new Set(locations.all.filter(l => l.continent === continent).map(l => l.country));
      countries = Array.from(countries).sort();
    }
    return countries && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>Country</label>
          <div className='control'>
            <select className='form-control' value={country} onChange={onCountryChange}>
              <option value="All">All</option>
              {countries.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderProvStates() {
    let states
    if (country !== 'All') {
      states = new Set(locations.all.filter(l => l.country === country).map(l => l.provence_state));
      states = Array.from(states).sort();
    }
    return states && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>Provence/State</label>
          <div className='control'>
            <select className='form-control' value={provState} onChange={onProvStateChange}>
              <option value="All">All</option>
              {states.map(s => (<option value={s}>{s}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderCities() {
    let cities;
    if (provState !== 'All') {
      cities = new Set(locations.all.filter(l => l.country === country && l.provence_state === provState).map(l => l.city));
      cities = Array.from(cities).sort();
    }
    return cities && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>City</label>
          <div className='control'>
            <select className='form-control' value={city} onChange={onCityChange}>
              <option value="All">All</option>
              {cities.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function onProductChange(evt) {
    console.log('Product ' + evt.target.name + ' checked ' + evt.target.checked)
    let update = selectedProducts.slice()
    if (evt.target.checked) {
      update = update.concat(services.filter(s => s === evt.target.name))
    } else {
      update = update.filter(s => s !== evt.target.name);
    }
    setSelectedProducts(update);
  }

  function onSourceChange(evt) {
    console.log('Serivce ' + evt.target.name + ' checked ' + evt.target.checked);
    let update = selectedSources.slice();
    if (evt.target.checked) {
      update = update.concat(sources.filter(s => s === evt.target.name));
    } else {
      update = update.filter(s => s != evt.target.name);
    }
    setSelectedSources(update);
  }

  return (
    <>
      <div className='row mb-4'>
        <div className='col-10'>
          <h1>New Sign Up Revenue</h1>
        </div>
        <div className='col'>
          <button className='btn btn-info btn-lg' onClick={fetchRevenueData}>Apply Filter</button>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-md-1'>
          <h3>Sources</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      <div className='row row-cols-3 mb-3'>
        {sources.map(src => (
          <div className='col'>
            <div className='form-check'>
              <input className='form-check-input' type='checkbox' name={src} onChange={onSourceChange} checked={selectedSources.includes(src)} id={src}/>
              <label htmlFor={src} className='form-check-label'>{src}</label>
            </div>
          </div>
        ))}
      </div>

      <div className='row'>
        <div className='col-md-1'>
          <h3>Products</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>
      
      <div className='row row-cols-3 mb-3'>
        {services.map(svc => (
          <div className='col'>
            <div className='form-check'>
              <input className='form-check-input' type='checkbox' name={svc} onChange={onProductChange} checked={selectedProducts.includes(svc)} id={svc}/>
              <label htmlFor={svc} className='form-check-label'>{cleanServiceName(svc)}</label>
            </div>
          </div>
        ))}
      </div>

      <div className='row mt-4'>
        <div className='col-md-1'>
          <h3>Dates</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-4'>
          <div className='field'>
            <label>Start</label>
            <div className='control'>
              <DatePicker onChange={handleStartChange} format="YYYY-MM-DD HH:mm:ss" date={start.toISO()} />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='field'>
            <label>End</label>
            <div className='control'>
              <DatePicker onChange={handleEndChange} format="YYYY-MM-DD HH:mm:ss" date={end.toISO()} />
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-1'>
          <h3>Locations</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      {renderContinents()}
      {renderCountries()}
      {renderProvStates()}
      {renderCities()}

      <div className='w-100 my-5'></div>

      <div className='row mb-5'>
        <div className='col'>
          {renderSignupRevenueLineChart()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-6'>
          {renderSourceRevenuePieChart()}
        </div>
        <div className='col-6'>
          {renderSignupRevenuePieChart()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-8'>
          {totalsByLocationBarChart()}
        </div>
        <div className='col-4'>
          {totalSignupRevenue()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-8'>
          {renderAveragePlanSizeBarChart()}
        </div>
        <div className='col-4'>
          {totalSignupsAveragePlanSize()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-8'>
          {avgPlanLengthByPlanBarChart()}
        </div>
        <div className='col-4'>
          {totalAvgPlanLength()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col'>
          {signupsSourceFrequencyBarChart()}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  fetchSignupsAction: fetchSignups,
  fetchLocationsAction: fetchLocations,
  fetchServicesAction: fetchServices,
  fetchSourcesAction: fetchSources
}

function mapStateToProps({ signupRevenue, locations, services, sources }) {
  return {
    serviceTotals: signupRevenue.serviceTotals,
    sourceTotals: signupRevenue.sourceTotals,
    averagePurchasesAmount: signupRevenue.averagePurchasesAmount,
    averagePlanLength: signupRevenue.averagePlanLength,
    dateTimeDomain: signupRevenue.dateTimeDomain,
    locationsTotals: signupRevenue.locationsTotals,
    locations,
    services: services.services,
    sources: sources.sources,
    registrationsInitiated: signupRevenue.registrationsInitiated,
    registrationsPaid: signupRevenue.registrationsPaid,
    registrationsNotPaid: signupRevenue.registrationsNotPaid
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpsPage);
