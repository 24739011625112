import { axiosInstance as axios } from '../../utils';
import { FETCH_SERVICES } from "./types";

export const fetchServices = () => async dispatch => {
  try {
    const response = await axios.get('/services');
    if (response.status == 200) {
      dispatch({ type: FETCH_SERVICES, payload: response.data });
    }
    return response;
  } catch(e) {
    console.log('Error fetching services', e);
    throw e;
  }
};
