
import { FETCH_LOCATIONS } from '../actions/types';

const initialState = {
  all: [],
  continents: [],
  countries: []
};

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_LOCATIONS:
      const continents = new Set();
      const countries = new Set();
      for (let loc of action.payload) {
        continents.add(loc.continent);
        countries.add(loc.country);
      }
      return { ...state, all: action.payload, continents: Array.from(continents).sort(), countries: Array.from(countries).sort() };
    default:
      return state;
  }
}
