import { axiosInstance as axios } from '../../utils';
import { FETCH_USER, AUTH_FAILURE, LOGIN_USER, LOGOUT_USER } from './types';

export function dispatchNotAuthorized() {
  return { type: AUTH_FAILURE, payload: { message: 'Unathenticated request' } };
}

export const fetchUser = () => async dispatch => {
  // redux thunk sees that the action returned
  // a function and it will call it passing
  // in a dispatch
    try {
      const response =  await axios.get('/user');
      if (response.status === 200) {
        dispatch({ type: FETCH_USER, payload: response.data });
      } else if (response.status === 401) {
        dispatch(dispatchNotAuthorized())
      }
      return response;
    } catch(e) {
      console.log('Error fetching user', e);
      throw e;
    }
};


export const loginUser = (data) => async dispatch => {
  try {
    const response = await axios.post('/login', data);
    if (response.status === 200) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      dispatch({ type: LOGIN_USER, payload: response.data })
    } else {
      dispatch(dispatchNotAuthorized())
    }
    return response;
  } catch(e) {
    console.log('Error logging user in', e);
    throw e;
  }
};

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER, payload: null });
};