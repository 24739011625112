import { axiosInstance as axios } from '../../utils';
import { FETCH_SOURCES } from "./types";

export const fetchSources = () => async dispatch => {
  try {
    const response = await axios.get('/sources');
    if (response.status == 200) {
      dispatch({ type: FETCH_SOURCES, payload: response.data });
    }
    return response;
  } catch(e) {
    console.log('Error fetching sources', e);
    throw e;
  }
};
