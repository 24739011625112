import { axiosInstance as axios } from '../../utils';
import _ from 'lodash';
import { FETCH_SIGNUP_REVENUE } from './types';

import { dispatchNotAuthorized } from './authActions';

export const fetchSignups = (options) => async dispatch => {
  const response = await axios.post('/signups', options);
  if (response.status === 200) {
    dispatch({ type: FETCH_SIGNUP_REVENUE, payload: response.data });
  } else if (response.status === 401) {
    dispatchNotAuthorized();
  }
};
