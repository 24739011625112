
import { FETCH_CHURN } from "../actions/types";

const initialState = {
  serviceTotals: [],
  userTotals: [],
  dateTimeDomain: [],
  userTypeTotals: {
    signups: 0,
    churned: 0,
    netnew: 0,
    active: 0
  }
};

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_CHURN:
      const data = action.payload;
      const updatedState = {
        ...state,
        serviceTotals: data.services_totals,
        userTotals: data.user_totals,
        dateTimeDomain: data.datetime_domain,
        userTypeTotals: data.user_types_totals
      }
      return updatedState;
    default:
      return state;
  }
}
