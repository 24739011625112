import axios from 'axios';
import _ from 'lodash';
import { DateTime } from 'luxon';

export function isAuthenticated(token) {
  token = token || localStorage.getItem('token')
  if (!token || token.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(token.split('.')[1]))
  const exp = new Date(data.exp * 1000)
  const now = new Date()
  return now < exp
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers["Authorization"] = 'Bearer ' + token;
  }
  return config;
})


export function commonChartToolbox() {
  return {
    feature: {
      saveAsImage: { title: 'Save As Image' },
      dataView: { readOnly: false, title: 'Data', lang: ['', 'Close', 'Refresh'] }
    }
  }
};

export function cleanServiceName(s) {
  s = s.replace('PageOptimizer ', '').replace('Unlimited Agency', 'Agency').replace('Yearly', 'Yr').replace('Monthly', 'Mo')
  if (s.includes('(')) {
    const size = s.substr(s.indexOf('(')).split(' ')[0] + ')';
    s = s.split('(')[0] + size
  }
  return s
}

export function defaultStartDate() {
  return DateTime.local().minus({months: 3}).startOf('month');
}

export function churnTimeSeriesChartOption(userCounts, dateTimeDomain) {
  const userTotalsByType = _.groupBy(userCounts, (u) => u.user_type);
  const userTypes = Object.keys(userTotalsByType);
  const series = [];

  for (let ut of userTypes) {
    const userTypeTotals = _.sortBy(userTotalsByType[ut], (u) => DateTime.fromHTTP(u.datetime));
    series.push({
      type:'line',
      name: ut,
      data: userTypeTotals.map(uc => ut === 'Churned' ? -1 * parseInt(uc.user_count) : uc.user_count)
    })
  }
  return {
    toolbox: commonChartToolbox(),
    tooltip: {
      trigger: 'item',
      formatter: (params) => `Date: ${params.name}<br>Name: ${params.seriesName}<br>Total: ${params.data}`
    },
    legend: {
      top: 'top',
      data: userTypes
    },
    xAxis: {
      type: 'category',
      data: dateTimeDomain.map(dt => dt.datetime_fmt)
    },
    yAxis: { formatter: (val) => `${val.toLocaleString()}` },
    dataZoom: [{
      type: 'slider',
      yAxisIndex: 0,
      filterMode: 'empty'
    }],
    series
  }
}

/**
 * Creates ECharts option object for multi-line chart with each 
 * option series being for a particular service.
 * 
 * @param servicesSeries [{
 *    datetime: ISO String,
 *    date_fmt: Preformatted Date String for Display,
 *    service: Service Package,
 *    value: Number }, ...]
 * @param dateTimeDomain [{
 *    datetime: ISO String,
 *    date_fmt: Preformatted Date String for Display
 *   }, ...]
 */
export function servicesRevenueTimeSeriesChartOption(servicesSeries, dateTimeDomain) {
  const svcTotalsByDatetime = _.groupBy(servicesSeries, (s) => s.service);
  const services = Object.keys(svcTotalsByDatetime);
  const series = []
  for (let svc of services) {
    let svcTotals = svcTotalsByDatetime[svc]
    series.push({
      name: cleanServiceName(svc),
      type: 'line',
      data: Array.from(_.sortBy(svcTotals, (s) => DateTime.fromISO(s.datetime))).map(s => s.value)
    })
  }
  return {
    toolbox: commonChartToolbox(),
    tooltip: {
      trigger: 'item',
      formatter: (params) => `Date: ${params.name}<br>Name: ${params.seriesName}<br>Total: $${params.data.toLocaleString()}`
    },
    legend: {
      top: 'top',
      data: services.map(s => cleanServiceName(s))
    },
    xAxis: {
      type: 'category',
      data: dateTimeDomain.map(s => s.datetime_fmt)
    },
    yAxis: { formatter: (val) =>  `$ ${val.toLocaleString()}` },
    series
  }
}

/**
 * Creates an ECharts option object for pie chart of totals by service
 * 
 * @param sourcesSeries [{ charge_amount: Number, consolidated_source: String }, ...]
 */
 export function sourceRevenuePieChartOption(sourcesSeries) {
  return {
    toolbox: commonChartToolbox(),
    legend: {
      top: 'top',
      data: sourcesSeries.map(s => s.consolidated_source)
    },
    series: [{
      name: 'Revenue Total',
      type: 'pie',
      radius: ['50%', '70%'],
      label: { show: false, position: 'center', formatter: `{b} \n $ {c}` },
      labelLine: { show: false },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold'
        }
      },
      data: sourcesSeries.map(item => {
        return { value: item.charge_amount, name: item.consolidated_source }
      })
    }]
  }
}

/**
 * Creates an ECharts option object for pie chart of totals by service
 * 
 * @param servicesSeries [{ amount: Number, service: String }, ...]
 */
export function serviceRevenuePieChartOption(servicesSeries) {
  return {
    toolbox: commonChartToolbox(),
    legend: {
      top: 'top',
      data: servicesSeries.map(s => cleanServiceName(s.service))
    },
    series: [{
      name: 'Revenue Total',
      type: 'pie',
      radius: ['50%', '70%'],
      label: { show: false, position: 'center', formatter: `{b} \n $ {c}` },
      labelLine: { show: false },
      emphasis: {
        label: {
          show: true,
          fontSize: '20',
          fontWeight: 'bold'
        }
      },
      data: servicesSeries.map(item => {
        return { value: item.amount, name: cleanServiceName(item.service) }
      })
    }]
  }
}

/**
 * Creates a ECharts option object for a barchart of average plan size by month
 * 
 * @param servicesSeries [{ average: Number, datetime: ISO String, datetime_fmt: Date String formatted for Display }, ...]
 */
export function averagePlanSizeByMonthBarChartOption(servicesSeries) {
  return {
    tooltip: {
      trigger: 'item',
      formatter: (params) => `Date: ${params.name}<br>Avg Plan Amount: $${params.data}`
    },
    xAxis: {
      type: 'category',
      data: servicesSeries.map(item => item.datetime_fmt)
    },
    yAxis: { formatter: (val) => `$ ${val.toLocaleString()}` },
    series: [{
      data: servicesSeries.map(item => parseFloat(item.average.toFixed(2))),
      type: 'bar'
    }]
  }
}

/**
 * Creates an Echarts option of revenue by location
 * 
 * @param locationsTotals [{  }, ...]
 */
export function revenueByLocationBarChartOption(locationsTotals) {
  return {
    toolbox: commonChartToolbox(),
    tooltip: {
      trigger: 'item',
      formatter: (params) => `Location: ${params.name}<br>Total Revenue: $ ${params.data.toLocaleString()}`
    },
    xAxis: { type: 'value' },
    yAxis: {
      type: 'category',
      data: locationsTotals.map(item => item.location),
      axisLabel: {
        rotate: 32,
        width: 206
      }
    },
    series: [{
      data: locationsTotals.map(item => parseFloat(item.value).toFixed(2)),
      type: 'bar'
    }]
  }
}

export function signupSourceFrequencyBarChartOption(initiated, paid, notpaid) {
  return {
    grid: {
      top: 30,
      bottom: 60,
      left: 460,
      right: 25
    },
    toolbox: commonChartToolbox(),
    tooltip: {
      trigger: 'item',
      formatter: (params) => `Source: ${params.name}<br>Count: ${params.data} <br>Type: ${params.seriesName}`
    },
    legend: { data: ['initiated', 'unpaid', 'paid'] },
    xAxis: { type: 'value' },
    yAxis: {
      inverse: true,
      type: 'category',
      data: initiated.map(item => item.consolidated_source)
    },
    series: [{
      name: 'initiated',
      data: initiated.map(item => item.count),
      type: 'bar'
    },{
      name: 'unpaid',
      data: notpaid.map(item => item.count),
      type: 'bar'
    },{
      name: 'paid',
      data: paid.map(item => item.count),
      type: 'bar'
    }]
  }
}

export function averagePlanLengthBarChartOption(servicesSeries) {
  return {
    toolbox: commonChartToolbox(),
    tooltip: {
      trigger: 'item',
      formatter: (params) => `Plan: ${params.name}<br>Avg Plan Length: ${params.data} days`
    },
    xAxis: { type: 'value' },
    yAxis: {
      type: 'category',
      data: servicesSeries.map(s => cleanServiceName(s.service))
    },
    series: [{
      data: servicesSeries.map(s => parseFloat(s.average_days.toFixed(2))),
      type: 'bar'
    }]
  }
}
