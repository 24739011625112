import React from 'react';

function EconomicsPage() {
  return (
    <>
      <h1>Economics</h1>
    </>
  );
}

export default EconomicsPage;
