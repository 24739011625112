import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../utils';

class Header extends Component {
  renderNavLinks() {
    if (isAuthenticated(this.props.auth.token)) {
      return (
        <Nav>
          <Nav.Link as={NavLink} to='/' exact>Home</Nav.Link>
          <Nav.Link as={NavLink} to='/signups'>Sign Ups</Nav.Link>
          <Nav.Link as={NavLink} to='/mrr'>MRR</Nav.Link>
          <Nav.Link as={NavLink} to='/churn'>Churn</Nav.Link>
          <Nav.Link as={NavLink} to='/funnel'>Funnel</Nav.Link>
          <Nav.Link as={NavLink} to='/economics'>Economics</Nav.Link>
          <Nav.Link as={NavLink} to='/financial'>Financial</Nav.Link>
          <Nav.Link as={NavLink} to='/logout'>Logout</Nav.Link>
        </Nav>
      );
    }
    return (
      <Nav>
        <Nav.Link as={NavLink} to='/login'>Login</Nav.Link>
      </Nav>
    );
  }

  render() {
    return (
      <Navbar bg='light' expand='lg'>
        <Navbar.Brand as={NavLink} to='/'>POP Dashboard</Navbar.Brand>
        {this.renderNavLinks()}
      </Navbar>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, null)(Header);
