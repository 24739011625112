import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../redux/actions/authActions';

function LogoutPage({ logoutUserAction }) {
  useEffect(() => {
    logoutUserAction();
  }, []);
 
  return (
    <>
      <h1>Logging Out</h1>
      <Redirect to='/login'/>
    </>
  );
}

const mapDispatchToProps = {
  logoutUserAction: logoutUser
};

export default connect(null, mapDispatchToProps)(LogoutPage);
