import React, { useEffect, useState } from 'react';
import { fetchMrr } from "../redux/actions/mrrMetricsActions";
import { fetchServices } from "../redux/actions/servicesActions";
import { fetchLocations } from "../redux/actions/locationsActions";
import { connect } from 'react-redux';
import { DatePicker } from "react-tempusdominus-bootstrap";
import { DateTime } from 'luxon';
import _ from 'lodash';
import ReactEcharts from 'echarts-for-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { cleanServiceName,
  defaultStartDate,
  servicesRevenueTimeSeriesChartOption,
  serviceRevenuePieChartOption,
  averagePlanSizeByMonthBarChartOption,
  revenueByLocationBarChartOption,
} from "../utils";

function MrrPage({
  fetchMrrAction,
  fetchLocationsAction,
  fetchServicesAction,
  revenueTotals,
  mrrBreakdown,
  averagePurchasesAmount,
  dateTimeDomain,
  locationsTotals,
  locations,
  services
}) {
  const [start, setStart] = useState(defaultStartDate());
  const [end, setEnd] = useState(DateTime.local().endOf('day'));
  const [granularity, setGranularity] = useState('month');
  const [continent, setContinent] = useState('All');
  const [country, setCountry] = useState('All');
  const [provState, setProvState] = useState('All');
  const [city, setCity] = useState('All');
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (_.isEmpty(locations.all)) {
      fetchLocationsAction();
    }
    if (_.isEmpty(services)) {
      fetchServicesAction().then(response => {
        setSelectedProducts(response.data);
        return fetchRevenueData({ selectedProducts: response.data })
      })
    } else {
      setSelectedProducts(services);
      fetchRevenueData({ selectedProducts: services })
    }
  }, [])

  function fetchRevenueData(opts = {}) {
    if (end && end > start) {
      fetchMrrAction({
        start: opts.start || start.toISODate(),
        end: opts.end || end.toISODate(),
        continent: opts.continent || continent,
        country: opts.country || country,
        provenceState: opts.provState || provState,
        city: opts.city || city,
        services: opts.selectedProducts || selectedProducts
      })
    }
  }

  function granularityDisplay() {
    return granularity.split('').map((char, i) => i === 0 ? char.toUpperCase() : char).join('')
  }

  function handleStartChange(evt) {
    setStart(DateTime.fromISO(evt.date.toISOString()))
  }

  function handleEndChange(evt) {
    setEnd(DateTime.fromISO(evt.date.toISOString()))
  }

  function onContinentChange(evt) {
    const val = evt.target.value
    setContinent(val);
    setCountry('All');
    setProvState('All');
    setCity('All');
  }

  function onCountryChange(evt) {
    const val = evt.target.value
    setCountry(val);
    setProvState('All');
    setCity('All');
  }

  function onProvStateChange(evt) {
    const val = evt.target.value
    setProvState(val);
    setCity('All');
  }

  function onCityChange(evt) {
    setCity(evt.target.value);
  }

  function onProductChange(evt) {
    let update = selectedProducts.slice()
    if (evt.target.checked) {
      update = update.concat(services.filter(s => s === evt.target.name))
    } else {
      update = update.filter(s => s !== evt.target.name);
    }
    setSelectedProducts(update);
  }

  function renderMrrBreakdownTimeLineChart() {
    let option = {}
    if (!_.isEmpty(mrrBreakdown)) {
      option = servicesRevenueTimeSeriesChartOption(mrrBreakdown.service_totals_by_datetime, dateTimeDomain)
    }
    const title = 'Revenue Breakdown by Month'
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>{title}
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-breakdown-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-breakdown-top">
                    Monthly breakdown of New Revenue, Recurring Revenue (eg, not churned and not new user revenue), and Churned Revenue from lost customers
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderRevenueByTimeLineChart() {
    let option = {}
    if (!_.isEmpty(revenueTotals)) {
      option = servicesRevenueTimeSeriesChartOption(revenueTotals.service_totals_by_datetime, dateTimeDomain)
    }
    const title = 'Revenue by Service and Month'

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>{title}
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-service-and-month-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-service-and-month-top">
                    Monthly Revenue by Service (excluding new member revenue)
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderRevenueByServicePieChart() {
    let option = {}
    if (!_.isEmpty(revenueTotals)) {
      option = serviceRevenuePieChartOption(revenueTotals.totals_by_service);
    }
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>Revenue by Service
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-service-totals-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-service-totals-top">
                    Revenue by Service Totals (excluding new member revenue)
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderAveragePlanSizeBarChart() {
    let option = {}
    let title = ''
    if (!_.isEmpty(averagePurchasesAmount)) {
      option = averagePlanSizeByMonthBarChartOption(averagePurchasesAmount.average_by_datetime)
      title = `Average Plan Size by Month`
    }

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
        <h4 className='card-title'>{title}
          <span className='float-right'>
              <OverlayTrigger
                key='monthly-revenue-avg-plan-size-top'
                placement='top'
                overlay={
                  <Tooltip id="monthly-revenue-avg-plan-size-top">
                    Average Plan Revenue Each Month (excluding new member revenue)
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
        </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderRevenueAveragePlanSize() {
    let totalAvgPlanSize = 0
    if (!_.isEmpty(averagePurchasesAmount)) {
      const avg = parseFloat(averagePurchasesAmount.overall_average.toFixed(2))
      totalAvgPlanSize = avg ? (<h3 className='display-2'>{`$${avg}`}</h3>) : (<h3 >Unknown</h3>);
    }

    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>Overall Average Plan Size
            <span className='float-right'>
              <OverlayTrigger
                key='monthly-revenue-avg-plan-total-size-top'
                placement='top'
                overlay={
                  <Tooltip id="monthly-revenue-avg-plan-total-size-top">
                    Average Plan Revenue across all months (excluding new member revenue)
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div className="d-flex align-items-center justify-content-center" style={{height: '350px'}}>
            {totalAvgPlanSize}
          </div>
        </div>
      </div>
    );
  }

  function totalsByLocationBarChart() {
    let option = {}
    if (!_.isEmpty(locationsTotals)) {
      option = revenueByLocationBarChartOption(locationsTotals)
    }
    return (
      <div className='card chart-container-md' style={{height: 550}}>
        <div className='card-body'>
          <h4 className='card-title'>Revenue by Location
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-by-location-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-by-location-top">
                    Revenue by Location (excluding new member revenue)
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '540px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function totalRevenue() {
    let totalRev = 0
    if (!_.isEmpty(locationsTotals)) {
      totalRev = Math.round(locationsTotals.reduce((acc, cur) => acc + cur.value, 0))
    }
    return (
      <div className='card chart-container-md' style={{height: 550}}>
        <div className='card-body'>
          <h4 className='card-title'>Total Revenue
            <span className='float-right'>
              <OverlayTrigger
                key='revenue-total-top'
                placement='top'
                overlay={
                  <Tooltip id="revenue-total-top">
                    Total Revenue (excluding new member revenue)
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div className="d-flex align-items-center justify-content-center" style={{height: '450px'}}>
            <h2 className='display-2'>${totalRev.toLocaleString()}</h2>
          </div>
        </div>
      </div>
    );
  }

  function renderContinents() {
    return !_.isEmpty(locations.continents) && (
      <div className='form-group row'>
        <div className='col-md-8'>
          <label>Continent</label>
          <div className='control'>
            <select className='form-control' value={continent} onChange={onContinentChange}>
              <option value="All">All</option>
              {locations.continents.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderCountries() {
    let countries
    if (continent !== 'All') {
      countries = new Set(locations.all.filter(l => l.continent === continent).map(l => l.country));
      countries = Array.from(countries).sort();
    }
    return countries && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>Country</label>
          <div className='control'>
            <select className='form-control' value={country} onChange={onCountryChange}>
              <option value="All">All</option>
              {countries.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderProvStates() {
    let states
    if (country !== 'All') {
      states = new Set(locations.all.filter(l => l.country === country).map(l => l.provence_state));
      states = Array.from(states).sort();
    }
    return states && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>Provence/State</label>
          <div className='control'>
            <select className='form-control' value={provState} onChange={onProvStateChange}>
              <option value="All">All</option>
              {states.map(s => (<option value={s}>{s}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderCities() {
    let cities;
    if (provState !== 'All') {
      cities = new Set(locations.all.filter(l => l.country === country && l.provence_state === provState).map(l => l.city));
      cities = Array.from(cities).sort();
    }
    return cities && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>City</label>
          <div className='control'>
            <select className='form-control' value={city} onChange={onCityChange}>
              <option value="All">All</option>
              {cities.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='row mb-4'>
        <div className='col-10'>
          <h1>Monthly Recurring Revenue</h1>
        </div>
        <div className='col'>
          <button className='btn btn-info btn-lg' onClick={fetchRevenueData}>Apply Filter</button>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-md-1'>
          <h3>Products</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>
      
      <div className='row row-cols-3 mb-3'>
        {services.map(svc => (
          <div className='col'>
            <div className='form-check'>
              <input className='form-check-input' type='checkbox' name={svc} onChange={onProductChange} checked={selectedProducts.includes(svc)} id={svc}/>
              <label htmlFor={svc} className='form-check-label'>{cleanServiceName(svc)}</label>
            </div>
          </div>
        ))}
      </div>

      <div className='row mt-4'>
        <div className='col-md-1'>
          <h3>Dates</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-4'>
          <div className='field'>
            <label>Start</label>
            <div className='control'>
              <DatePicker onChange={handleStartChange} format="YYYY-MM-DD HH:mm:ss" date={start.toISO()} />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='field'>
            <label>End</label>
            <div className='control'>
              <DatePicker onChange={handleEndChange} format="YYYY-MM-DD HH:mm:ss" date={end.toISO()} />
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-1'>
          <h3>Locations</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      {renderContinents()}
      {renderCountries()}
      {renderProvStates()}
      {renderCities()}

      <div className='w-100 my-5'></div>

      <div className='row mb-5'>
        <div className='col-12'>
          {renderMrrBreakdownTimeLineChart()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-8'>
          {renderRevenueByTimeLineChart()}
        </div>
        <div className='col-4'>
          {renderRevenueByServicePieChart()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-8'>
          {totalsByLocationBarChart()}
        </div>
        <div className='col-4'>
          {totalRevenue()}
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-8'>
          {renderAveragePlanSizeBarChart()}
        </div>
        <div className='col-4'>
          {renderRevenueAveragePlanSize()}
        </div>
      </div>

    </>
  );
}

const mapDispatchToProps = {
  fetchMrrAction: fetchMrr,
  fetchLocationsAction: fetchLocations,
  fetchServicesAction: fetchServices
};

function mapStateToProps({ mrrRevenue, locations, services }) {
  return {
    revenueTotals: mrrRevenue.revenueTotals,
    averagePurchasesAmount: mrrRevenue.averagePurchasesAmount,
    dateTimeDomain: mrrRevenue.dateTimeDomain,
    locationsTotals: mrrRevenue.locationsTotals,
    mrrBreakdown: mrrRevenue.mrrBreakdown,
    locations,
    services: services.services
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MrrPage);
