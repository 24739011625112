
import { FETCH_SIGNUP_REVENUE } from '../actions/types';

const initialState = {
  signupsGranularity: 'month',
  serviceTotals: [],
  averagePurchasesAmount: [],
  averagePlanLength: [],
  dateTimeDomain: [],
  locationsTotals: [],
  sourceTotals: [],
  registrationsInitiated: [],
  registrationsPaid: [],
  registrationsNotPaid: []
};

export default function(state = initialState, action) {
  console.log('signupsReducer', action);
  switch(action.type) {
    case FETCH_SIGNUP_REVENUE:
      console.log('FETCH_SIGNUP_REVENUE');

      const signupsData = action.payload

      const updatedState = {
        ...state,
        serviceTotals: signupsData.services_totals,
        averagePurchasesAmount: signupsData.average_purchases_amount,
        averagePlanLength: signupsData.average_plan_length,
        dateTimeDomain: signupsData.datetime_domain,
        locationsTotals: signupsData.locations_totals,
        sourceTotals: signupsData.source_totals,
        registrationsInitiated: signupsData.registrations_initiated,
        registrationsPaid: signupsData.registrations_paid,
        registrationsNotPaid: signupsData.registrations_notpaid
      }

      return updatedState;
    default:
      return state;
  }
}
