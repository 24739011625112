
import { FETCH_USER, AUTH_FAILURE, LOGIN_USER, LOGOUT_USER } from '../actions/types';

const initialState = {
  user: null,
  token: null
};

export default function(state = initialState, action) {
  console.log('authReducer', action);
  switch(action.type) {
    case FETCH_USER:
      console.log('FETCH_USER');
      return { ...state, user: action.payload };
    case AUTH_FAILURE:
      console.log('AUTH_FAILURE');
      localStorage.clear();
      return initialState;
    case LOGIN_USER:
      console.log('LOGIN_USER');
      localStorage.setItem('token', action.payload.token);
      return { ...state, token: action.payload.token };
    case LOGOUT_USER:
      localStorage.clear();
      return initialState;
    default:
      return state;
  }
}
