
import { FETCH_HOME } from "../actions/types";

const initialState = {
  users: [],
  mrr: [],
  dateTimeDomain: []
};

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_HOME:
      const data = action.payload;
      const updatedState = {
        ...state,
        users: data.users,
        dateTimeDomain: data.datetime_domain,
        mrr: data.mrr
      }
      return updatedState;
    default:
      return state;
  }
}
