import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';


function HomeTile(props) {
  return (
    <Link className='card mb-3 text-center bg-light' style={{textDecoration: 'none'}} to={props.to}>
      <div className='card-body py-4'>
        <h5 className='card-title'>{props.title}</h5>
        <p className='card-text'>{props.subtitle}</p>
      </div>
    </Link>
  );
}

HomeTile.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}

export default HomeTile;
