import React, { Component , useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import * as authActions from './redux/actions/authActions';

import Header from './components/common/Header.js';
import PageNotRound from './components/PageNotFound.js';
import HomePage from './components/HomePage.js';
import ChurnPage from './components/ChurnPage.js';
import EconomicsPage from './components/EconomicsPage.js';
import FinancialsPage from './components/FinancialsPage.js';
import MrrPage from './components/MrrPage.js';
import SignUpsPage from './components/SignUpsPage.js';
import LoginPage from './components/LoginPage.js';
import LogoutPage from './components/LogoutPage.js';
import { isAuthenticated } from './utils';

function PrivateRoute({ component: Component, authCheck, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => authCheck() ? <Component {...props}/> : <Redirect to='/login'/>}
    />
  );
}

function App ({ auth, authActions }) {
  const history = useHistory();
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const storageToken = localStorage.getItem('token')
    if (!storageToken && !auth.token) {
      console.log('should redirect to login page');
      history.push('/login')
    }
    setReady(true);
  }, [])

  return isReady && (
    <div>
      <Header/>
      <div className='container-fluid mt-4'>
        <Switch>
          <Route path='/login' component={LoginPage}/>
          <Route path='/logout' component={LogoutPage}/>
          <PrivateRoute path='/' exact component={HomePage} authCheck={() => isAuthenticated(auth.token)}/>
          <PrivateRoute path='/churn' component={ChurnPage} authCheck={() => isAuthenticated(auth.token)}/>
          <PrivateRoute path='/economics' component={EconomicsPage} authCheck={() => isAuthenticated(auth.token)}/>
          <PrivateRoute path='/financials' component={FinancialsPage} authCheck={() => isAuthenticated(auth.token)}/>
          <PrivateRoute path='/mrr' component={MrrPage} authCheck={() => isAuthenticated(auth.token)}/>
          <PrivateRoute path='/signups' component={SignUpsPage} authCheck={() => isAuthenticated(auth.token)}/>
          <Route component={PageNotRound}/>
        </Switch>
      </div>
    </div>
  );
}

function mapStateToProps({ auth }) {
  return {
    auth
  };
}

const mapDispatchToProps = {
  authActions
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
