import { axiosInstance as axios } from '../../utils';
import _ from 'lodash';

import { FETCH_CHURN } from "./types";
import { dispatchNotAuthorized } from './authActions';

export const fetchChurn = (options) => async dispatch => {
  const response = await axios.post('/churn', options);
  if (response.status === 200) {
    dispatch({ type: FETCH_CHURN, payload: response.data });
  } else if (response.status === 401) {
    dispatchNotAuthorized();
  }
  return response;
};
