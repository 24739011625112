
import { FETCH_SOURCES } from '../actions/types';

const initialState = {
  sources: []
};

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_SOURCES:
      return { ...state, sources: action.payload };
    default:
      return state;
  }
}
