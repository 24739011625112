import React from 'react';

function FinancialsPage() {
  return (
    <>
      <h1>Financials</h1>
    </>
  );
}

export default FinancialsPage;
