import React, { useEffect } from 'react';
import _ from 'lodash';
import { fetchHomeData } from "../redux/actions/homeActions";
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux';
import {
  servicesRevenueTimeSeriesChartOption,
  churnTimeSeriesChartOption
} from "../utils";

import HomeTile from './common/HomeTile.js'

function HomePage({ mrr, users, dateTimeDomain, fetchHomeDataAction }) {
  useEffect(() => {
    fetchHomeDataAction();
  }, []);

  function renderSpinner() {
    return (
      <div class="d-flex align-items-center justify-content-center h-100">
        <div class="spinner-border" role="status" style={{ width: '6em', height: '6em' }}>
          {/* <span class="visually-hidden">Loading...</span> */}
        </div>
      </div>
    );
  }

  function renderUserCountsLineChart() {
    let option = {}
    if (!_.isEmpty(users)) {
      option = churnTimeSeriesChartOption(users, dateTimeDomain)
      const minYVal = _.min(option.series[0].data) * 0.7
      option.yAxis.min = minYVal;
      delete option.legend;
      delete option.dataZoom;
    }
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>Users</h4>
          <div className='h-100'>
            { _.isEmpty(option) 
              ? (renderSpinner()) 
              : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderMrrBreakdownTimeLineChart() {
    let option = {}
    if (!_.isEmpty(mrr)) {
      option = servicesRevenueTimeSeriesChartOption(mrr, dateTimeDomain)
      delete option.legend;
    }
    const title = 'Revenue'
    return (
      <div className='card chart-container-md'>
        <div className='card-body align-items-center'>
          <h4 className='card-title'>{title}</h4>
          <div className='h-100'>
            { _.isEmpty(option) 
              ? ((renderSpinner())) 
              : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h3 className='my-4 text-center'>Welcome to the POP Dashboard!</h3>
      <div className='row mb-5'>
        <div className='col-md-6 col-sm-12'>{renderMrrBreakdownTimeLineChart()}</div>
        <div className='col-md-6 col-sm-12'>{renderUserCountsLineChart()}</div>
      </div>
      <div className='row row-cols-2 justify-content-center'>
        <div className='col'>
          <HomeTile to='/signups' title='Sign Ups' subtitle='Metrics on new signups.'/>
        </div>
        <div className='col'>
          <HomeTile to='/mrr' title='MRR' subtitle='Metrics on Monthly Recurring Revenue (MRR).'/>
        </div>
        <div className='col'>
          <HomeTile to='/churn' title='Churn' subtitle='Metrics on Customer Churn.'/>
        </div>
        <div className='col'>
          <HomeTile to='/economics' title='Economics' subtitle='Metrics on Economics.'/>
        </div>
        <div className='col'>
          <HomeTile to='/financials' title='Financials' subtitle='Metrics on Financials.'/>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  fetchHomeDataAction: fetchHomeData
};

function mapStateToProps({ home }) {
  return {
    mrr: home.mrr,
    dateTimeDomain: home.dateTimeDomain,
    users: home.users
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
