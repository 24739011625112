import { combineReducers } from 'redux';
import authReducer from './authReducer';
import signupsReducer from './signupsReducer';
import mrrReducer from './mrrReducer';
import locationsReducer from './locationsReducer';
import sourcesReducer from './sourcesReducer';
import servicesReducer from './servicesReducer';
import churnReducer from './churnReducer';
import homeReducer from './homeReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  signupRevenue: signupsReducer,
  mrrRevenue: mrrReducer,
  locations: locationsReducer,
  services: servicesReducer,
  churn: churnReducer,
  home: homeReducer,
  sources: sourcesReducer
});

export default rootReducer;
