
import { FETCH_MRR } from "../actions/types";

const initialState = {
  revenueTotals: [],
  averagePurchasesAmount: [],
  dateTimeDomain: [],
  locationsTotals: [],
  mrrBreakdown: []
};

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_MRR:
      const data = action.payload;
      const updatedState = {
        ...state,
        revenueTotals: data.services_totals,
        averagePurchasesAmount: data.average_purchases_amount,
        dateTimeDomain: data.datetime_domain,
        locationsTotals: data.locations_totals,
        mrrBreakdown: data.mrr_breakdown
      }
      return updatedState;
    default:
      return state;
  }
}
