import React, { useEffect, useState } from 'react';
import { fetchChurn } from "../redux/actions/churnActions";
import { fetchServices } from "../redux/actions/servicesActions";
import { fetchLocations } from "../redux/actions/locationsActions";
import { connect } from 'react-redux';
import { DatePicker } from "react-tempusdominus-bootstrap";
import { DateTime } from 'luxon';
import _ from 'lodash';
import ReactEcharts from 'echarts-for-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { cleanServiceName, defaultStartDate, churnTimeSeriesChartOption } from "../utils";


function ChurnPage({ fetchChurnAction, fetchLocationsAction, fetchServicesAction, churn, currentActive, totalChurn, totalSignups, totalNetNew, locations, services }) {
  const [start, setStart] = useState(defaultStartDate());
  const [end, setEnd] = useState(DateTime.local().endOf('day'));
  const [granularity, setGranularity] = useState('month');
  const [continent, setContinent] = useState('All');
  const [country, setCountry] = useState('All');
  const [provState, setProvState] = useState('All');
  const [city, setCity] = useState('All');
  const [selectedProducts, setSelectedProducts] = useState([]);
  
  useEffect(() => {
    if (_.isEmpty(locations.all)) {
      fetchLocationsAction();
    }
    if (_.isEmpty(services)) {
      fetchServicesAction().then(response => {
        setSelectedProducts(response.data);
        return fetchChurnData({ selectedProducts: response.data })
      })
    } else {
      setSelectedProducts(services);
      fetchChurnData({ selectedProducts: services })
    }
  }, []);

  function fetchChurnData(opts = {}) {
    if (end && end > start) {
      fetchChurnAction({
        start: opts.start || start.toISODate(),
        end: opts.end || end.toISODate(),
        continent: opts.continent || continent,
        country: opts.country || country,
        provenceState: opts.provState || provState,
        city: opts.city || city,
        services: opts.selectedProducts || selectedProducts
      });
    }
  }

  function onProductChange(evt) {
    console.log('Product ' + evt.target.name + ' checked ' + evt.target.checked)
    let update = selectedProducts.slice()
    if (evt.target.checked) {
      update = update.concat(services.filter(s => s === evt.target.name))
    } else {
      update = update.filter(s => s !== evt.target.name);
    }
    setSelectedProducts(update);
  }

  function handleStartChange(evt) {
    setStart(DateTime.fromISO(evt.date.toISOString()))
  }

  function handleEndChange(evt) {
    setEnd(DateTime.fromISO(evt.date.toISOString()))
  }

  function onContinentChange(evt) {
    const val = evt.target.value
    setContinent(val);
    setCountry('All');
    setProvState('All');
    setCity('All');
  }

  function onCountryChange(evt) {
    const val = evt.target.value
    setCountry(val);
    setProvState('All');
    setCity('All');
  }

  function onProvStateChange(evt) {
    const val = evt.target.value
    setProvState(val);
    setCity('All');
  }

  function onCityChange(evt) {
    setCity(evt.target.value);
  }

  function renderContinents() {
    return !_.isEmpty(locations.continents) && (
      <div className='form-group row'>
        <div className='col-md-8'>
          <label>Continent</label>
          <div className='control'>
            <select className='form-control' value={continent} onChange={onContinentChange}>
              <option value="All">All</option>
              {locations.continents.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderCountries() {
    let countries
    if (continent !== 'All') {
      countries = new Set(locations.all.filter(l => l.continent === continent).map(l => l.country));
      countries = Array.from(countries).sort();
    }
    return countries && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>Country</label>
          <div className='control'>
            <select className='form-control' value={country} onChange={onCountryChange}>
              <option value="All">All</option>
              {countries.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderProvStates() {
    let states
    if (country !== 'All') {
      states = new Set(locations.all.filter(l => l.country === country).map(l => l.provence_state));
      states = Array.from(states).sort();
    }
    return states && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>Provence/State</label>
          <div className='control'>
            <select className='form-control' value={provState} onChange={onProvStateChange}>
              <option value="All">All</option>
              {states.map(s => (<option value={s}>{s}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  function renderCities() {
    let cities;
    if (provState !== 'All') {
      cities = new Set(locations.all.filter(l => l.country === country && l.provence_state === provState).map(l => l.city));
      cities = Array.from(cities).sort();
    }
    return cities && (
      <div className='row form-group'>
        <div className='col-md-8'>
          <label>City</label>
          <div className='control'>
            <select className='form-control' value={city} onChange={onCityChange}>
              <option value="All">All</option>
              {cities.map(c => (<option value={c}>{c}</option>))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  
  function renderUserCountsLineChart() {
    let option = {}
    if (!_.isEmpty(churn.userTotals)) {
      option = churnTimeSeriesChartOption(churn.userTotals.filter(s => s.service === 'All'), churn.dateTimeDomain)
    }
    return (
      <div className='card chart-container-md'>
        <div className='card-body'>
          <h4 className='card-title'>User Breakdown Over Time
            <span className='float-right'>
              <OverlayTrigger
                key='user-breakdown-timeseries-top'
                placement='left'
                overlay={
                  <Tooltip id="user-breakdown-timeseries-top">
                    Churned: People who cancelled service<br/>
                    Signups: People who paid for the service for the first time<br/>
                    Existing: People who have been on going users (not new signups and not churned)<br/>
                    Net New: New signups minus churn<br/>
                    Total Active: Signups plus existing
                  </Tooltip>
                }>
                <i className='fa fa-info-circle' aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
          </h4>
          <div>
            { _.isEmpty(option) ? (<p className="text-center">No data available</p>) : (<ReactEcharts option={option} className='react_for_echarts' style={{height: '350px', width: '100%'}}/>)}
          </div>
        </div>
      </div>
    );
  }

  function renderUserTypeCounts() {
    return (
      <div className='row mb-4'>
      <div className='col'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Total Active
              <span className='float-right'>
                <OverlayTrigger
                  key='total-active-users-top'
                  placement='top'
                  overlay={
                    <Tooltip id="total-active-users-top">
                      Total Active: Signups plus existing
                    </Tooltip>
                  }>
                  <i className='fa fa-info-circle' aria-hidden="true"></i>
                </OverlayTrigger>
              </span>
            </h4>
            <div className="d-flex align-items-center justify-content-center" style={{height: '160px'}}>
              <h2 className='display-2'>{currentActive}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Sign Ups
              <span className='float-right'>
                <OverlayTrigger
                  key='total-active-users-top'
                  placement='top'
                  overlay={
                    <Tooltip id="total-active-users-top">
                      Signups: People who paid for the service for the first time
                    </Tooltip>
                  }>
                  <i className='fa fa-info-circle' aria-hidden="true"></i>
                </OverlayTrigger>
              </span>
            </h4>
            <div className="d-flex align-items-center justify-content-center" style={{height: '160px'}}>
              <h2 className='display-2'>{totalSignups}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Churned
              <span className='float-right'>
                <OverlayTrigger
                  key='total-active-users-top'
                  placement='top'
                  overlay={
                    <Tooltip id="total-active-users-top">
                      Churned: People who cancelled service
                    </Tooltip>
                  }>
                  <i className='fa fa-info-circle' aria-hidden="true"></i>
                </OverlayTrigger>
              </span>
            </h4>
            <div className="d-flex align-items-center justify-content-center" style={{height: '160px'}}>
              <h2 className='display-2'>{totalChurn}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Net New
              <span className='float-right'>
                <OverlayTrigger
                  key='total-active-users-top'
                  placement='top'
                  overlay={
                    <Tooltip id="total-active-users-top">
                      Net New: New signups minus churn
                    </Tooltip>
                  }>
                  <i className='fa fa-info-circle' aria-hidden="true"></i>
                </OverlayTrigger>
              </span>
            </h4>
            <div className="d-flex align-items-center justify-content-center" style={{height: '160px'}}>
              <h2 className='display-2'>{totalNetNew}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

  return (
    <>
      <div className='row mb-4'>
        <div className='col-10'>
          <h1>User Churn</h1>
        </div>
        <div className='col'>
          <button className='btn btn-info btn-lg' onClick={fetchChurnData}>Apply Filter</button>
        </div>
      </div>
      
      <div className='row'>
        <div className='col-md-1'>
          <h3>Products</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      <div className='row row-cols-3 mb-3'>
        {services.map(svc => (
          <div className='col'>
            <div className='form-check'>
              <input className='form-check-input' type='checkbox' name={svc} onChange={onProductChange} checked={selectedProducts.includes(svc)} id={svc}/>
              <label htmlFor={svc} className='form-check-label'>{cleanServiceName(svc)}</label>
            </div>
          </div>
        ))}
      </div>

      <div className='row mt-4'>
        <div className='col-md-1'>
          <h3>Dates</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-4'>
          <div className='field'>
            <label>Start</label>
            <div className='control'>
              <DatePicker onChange={handleStartChange} format="YYYY-MM-DD HH:mm:ss" date={start.toISO()} />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='field'>
            <label>End</label>
            <div className='control'>
              <DatePicker onChange={handleEndChange} format="YYYY-MM-DD HH:mm:ss" date={end.toISO()} />
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-1'>
          <h3>Locations</h3>
        </div>
        <div className='col'>
          <hr className='divider'/>
        </div>
      </div>

      {renderContinents()}
      {renderCountries()}
      {renderProvStates()}
      {renderCities()}

      <div className='w-100 my-5'></div>

      {/* {renderUserTypeCounts()} */}

      <div className='row mb-5'>
        <div className='col-12'>
          {renderUserCountsLineChart()}
        </div>
      </div>

    </>
  );
}

const mapDispatchToProps = {
  fetchChurnAction: fetchChurn,
  fetchLocationsAction: fetchLocations,
  fetchServicesAction: fetchServices
};

function mapStateToProps({ churn, locations, services }) {
  return {
    churn,
    currentActive: churn.userTypeTotals.active,
    totalChurn: churn.userTypeTotals.churned,
    totalSignups: churn.userTypeTotals.signups,
    totalNetNew: churn.userTypeTotals.netnew,
    locations,
    services: services.services
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChurnPage);
