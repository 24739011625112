import { axiosInstance as axios } from '../../utils';
import { FETCH_LOCATIONS } from './types';

export const fetchLocations = () => async dispatch => {
  try {
    const response =  await axios.get('/locations');
    if (response.status === 200) {
      dispatch({ type: FETCH_LOCATIONS, payload: response.data });
    }
    return response;
  } catch(e) {
    console.log('Error fetching locations', e);
    throw e;
  }
};
