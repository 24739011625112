export const FETCH_USER = 'fetch_user';
export const AUTH_FAILURE = 'auth_failure';
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';

export const FETCH_SIGNUP_REVENUE = 'fetch_signup_revenue';
export const FETCH_MRR = 'fetch_mrr';
export const FETCH_CHURN = 'fetch_churn';

export const FETCH_LOCATIONS = 'fetch_locations';
export const FETCH_SOURCES = 'fetch_sources';
export const FETCH_SERVICES = 'fetch_services';

export const FETCH_HOME = 'fetch_home';
